import * as React from 'react'
import { useTranslate } from 'react-admin'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { Field, Form, Formik } from 'formik'
import { Container, Grid, makeStyles } from '@material-ui/core'
import MyTextField from '../../../components/form/fields/MyTextField'
import MyAutocomplete from '../../../components/form/fields/MyAutocomplete'
import DeleteConfirmationModal from '../../../components/ui/DeleteConfirmationModal/DeleteConfirmationModal'
import ButtonWithLoader from '../../../components/ui/ButtonWithLoader/ButtonWithLoader'
import useQueryParams from '../../../hooks/useQueryParams'
import TextButton from '../../../components/ui/TextButton/TextButton'
import useFormValidation from '../../../hooks/useFormValidation'
import teamType from '../../../types/teamType'
import mutationType from '../../../types/mutationType'
import teamSettingsFormStyles from './teamSettingsForm.styles'
import ageRestrictionType from '../../../types/ageRestrictionType'
import genderType from '../../../types/genderType'
import isUserOrgRolesValid from '../../../utils/helpers/isUserOrgRolesValid'
import USER_ORG_MEMBERS_ROLES from '../../../utils/constants/userOrgMembersRoles'
import USER_ORG_ROLES from '../../../utils/constants/userOrgRoles'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import emptyOrg from '../../../assets/images/org_empty.svg'
import ImageCropWithEdit from '../../../components/ui/ImageCropComponents/ImageCropWithEdit/ImageCropWithEdit'
import ColourPickerPopper from '../../../components/ColourPickerPopper/ColourPickerPopper'
import ORG_COLOURS_PALETTE from '../../../utils/constants/teamColoursPallete'

const TeamSettingsForm = (props) => {
  const {
    teamState,
    ageRestriction,
    gender,
    team,
    showDeleteModal,
    setShowDeleteModal,
    selectedTeamId,
    deleteTeam,
    updateTeam,
  } = props
  const { stringThreeLetters } = useFormValidation()
  const history = useHistory()
  const queryParams = useQueryParams()
  const translate = useTranslate()
  const useStyles = makeStyles(teamSettingsFormStyles)
  const classes = useStyles()

  const userHasNotOnlyTeamMemberRole = isUserOrgRolesValid(
    [USER_ORG_ROLES.ADMIN, USER_ORG_ROLES.OWNER, USER_ORG_MEMBERS_ROLES.TEAM_MANAGER],
    [selectedTeamId],
  )

  const onSubmit = (data) => {
    updateTeam.mutate({
      name: data?.name,
      threeLetterIdentifier: data?.threeLetterIdentifier,
      ageRestriction: data?.ageRestriction.id,
      gender: data?.gender.id,
      teamId: selectedTeamId,
      logo: data?.teamLogo,
      externalId: data?.externalId,
      color: data?.teamColor.value,
    })
  }

  const onDeleteTeam = () => {
    deleteTeam.mutate({ teamId: selectedTeamId })
    if (queryParams?.team === selectedTeamId) {
      history.push({
        pathname: '/teamSettings',
        search: `?org=${queryParams?.org}`,
      })
    }
  }

  return (
    <Container className={classes.formWrapper}>
      <Formik
        initialValues={{
          name: teamState.name,
          threeLetterIdentifier: teamState.threeLetterIdentifier,
          ageRestriction: teamState.ageRestriction,
          gender: teamState.gender,
          teamLogo: teamState.teamLogo,
          externalId: teamState.externalId,
          teamColor:
            teamState.color && ORG_COLOURS_PALETTE.find((col) => col.value === teamState.color),
        }}
        validationSchema={yup.object().shape({
          threeLetterIdentifier: stringThreeLetters,
        })}
        onSubmit={(data) => onSubmit(data)}
      >
        {(formik) => (
          <>
            <Form>
              <Grid
                item
                container
                direction='column'
                justifyContent='center'
                alignItems='center'
                style={{ marginTop: 10, marginBottom: 25 }}
              >
                <div>{translate('ra.text.teamAvatar')}</div>
                <ImageCropWithEdit
                  defaultImageSrc={formik.values?.teamLogo || ''}
                  setCroppedImage={(croppedImage) => formik.setFieldValue('teamLogo', croppedImage)}
                  dialogTitle='Edit your Team Logo'
                  emptyPreviewIcon={emptyOrg}
                />
              </Grid>
              <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={6}>
                  <MyTextField
                    label={translate('ra.formFields.teamName')}
                    name='name'
                    required
                    handleOnBlur={(e) => {
                      formik.setFieldValue(
                        'threeLetterIdentifier',
                        e.target.value.toUpperCase().substring(0, 3),
                      )
                    }}
                    disabled={!userHasNotOnlyTeamMemberRole}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MyTextField
                    label={translate('ra.formFields.shortName')}
                    name='threeLetterIdentifier'
                    required
                    disabled={!userHasNotOnlyTeamMemberRole}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3} justifyContent='center'>
                <Grid item xs={6}>
                  <Field
                    name='ageRestriction'
                    label={translate('ra.formFields.ageGroup')}
                    variant='filled'
                    required
                    options={ageRestriction || []}
                    component={MyAutocomplete}
                    getOptionLabel={(option) => option.name}
                    defaultValue={team.ageRestriction}
                    disabled={!userHasNotOnlyTeamMemberRole}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Field
                    name='gender'
                    label={translate('ra.formFields.gender')}
                    variant='filled'
                    required
                    options={gender || []}
                    component={MyAutocomplete}
                    getOptionLabel={(option) => option.name}
                    defaultValue={team.gender}
                    disabled={!userHasNotOnlyTeamMemberRole}
                  />
                </Grid>
                <Grid item xs={6} />
              </Grid>
              <Grid container spacing={3} justifyContent='center' alignItems='center'>
                <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <MyTextField
                    label={translate('ra.formFields.teamExternalId')}
                    name='externalId'
                    style={{ width: 200 }}
                    disabled={!userHasNotOnlyTeamMemberRole}
                  />
                </Grid>
                <Grid item xs={6}>
                  <ColourPickerPopper name='teamColor' palette={ORG_COLOURS_PALETTE} />
                </Grid>
              </Grid>
              <Grid container justifyContent='flex-end'>
                <ConditionalRender renderValue={userHasNotOnlyTeamMemberRole}>
                  <TextButton
                    onClick={() => setShowDeleteModal(true)}
                    label={translate('ra.buttons.delete')}
                    className={classes.deleteButton}
                  />
                  <ButtonWithLoader
                    label={translate('ra.buttons.update')}
                    style={{ marginTop: '25px' }}
                    disabled={!(formik.isValid && formik.dirty)}
                    loading={updateTeam.isLoading}
                  />
                </ConditionalRender>
              </Grid>
            </Form>
            <DeleteConfirmationModal
              isModalOpen={showDeleteModal}
              onClose={() => setShowDeleteModal(false)}
              title={translate('ra.text.deleteTeam')}
              subtitle={translate('ra.text.deleteTeamName')}
              comparisonValue={formik.values.name}
              buttonLabel={translate('ra.action.delete')}
              textFieldLabel={translate('ra.formFields.teamName')}
              onSubmit={onDeleteTeam}
              isLoading={deleteTeam.isLoading}
            />
          </>
        )}
      </Formik>
    </Container>
  )
}

TeamSettingsForm.propTypes = {
  teamState: teamType,
  ageRestriction: PropTypes.arrayOf(ageRestrictionType),
  gender: PropTypes.arrayOf(genderType),
  teamLogo: PropTypes.string,
  team: teamType,
  showDeleteModal: PropTypes.bool,
  setShowDeleteModal: PropTypes.func,
  selectedTeamId: PropTypes.string,
  deleteTeam: mutationType,
  updateTeam: mutationType,
}

export default TeamSettingsForm
