import { FormControl, makeStyles, Typography, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import { React, useState } from 'react'
import { useTranslate } from 'react-admin'
import ExpandableText from '../../../components/ExpandableText/ExpandableText'
import SubscriptionAddonCard from '../SubscriptionAddonCard/SubscriptionAddonCard'
import mainSubscriptionTabStyles from './mainSubscriptionTab.styles'
import SUBSCRIPTION_STATES from '../../../utils/constants/subscriptionStates'
import getSelectedOrgSubscriptionStatus from '../../../utils/helpers/getSelectedOrgSubscriptionStatus'
import useGetLocalStorage from '../../../hooks/useGetLocalStorage'
import localStorageIds from '../../../utils/constants/localStorageIds'
import orgIsInSubscriptionState from '../../../utils/helpers/orgIsInSubscriptionState'
import BillingSummary from '../BillingSummary'
import ConditionalRender from '../../../components/ConditionalRender/ConditionalRender'
import COLORS from '../../../utils/constants/colors'
import DeactivateSubscriptionConfirmationModal from './DeactivateSubscriptionConfirmationModal'
import hasNullRequiredValues from '../../../utils/constants/hasNullRequiredValues'

const MainSubscriptionTab = ({
  handleActivateAddon,
  handleDeactivateAddon,
  handleActivateSubscription,
  subscriptionDetails,
  orgId,
  setTab,
}) => {
  const translate = useTranslate()
  const localStorageValue = useGetLocalStorage()
  const {
    [localStorageIds.SUBSCRIPTIONS]: subsPerOrg,
    [localStorageIds.SELECTED_ORG]: selectedOrg,
  } = localStorageValue || {}
  const selectedOrgSubscriptionStatus = getSelectedOrgSubscriptionStatus(subsPerOrg, selectedOrg)

  const useStyles = makeStyles(mainSubscriptionTabStyles)
  const classes = useStyles()
  const [isDeactivateAddonOpen, setIsDeactivateAddonOpen] = useState(false)
  const [addon, setAddon] = useState()

  const handleCloseDeactivateConfirmationModal = () => {
    setIsDeactivateAddonOpen(false)
    setAddon(null)
  }
  const handleOpenDeactivateConfirmationModal = (addon) => {
    setIsDeactivateAddonOpen(true)
    setAddon(addon)
  }

  const SUBSCRIPTION_PAGE_TEXT = {
    [SUBSCRIPTION_STATES.TRIAL]: 'ra.text.activateOrgSubscription',
    [SUBSCRIPTION_STATES.ACTIVE]: 'ra.text.orgSubscription',
    [SUBSCRIPTION_STATES.GRACE]: 'ra.text.orgSubscription',
    [SUBSCRIPTION_STATES.INACTIVE]: 'ra.text.activateOrgSubscription',
  }
  const EXPANDABLE_TEXT = [
    {
      header: translate('ra.text.addingNewUser'),
      text: translate('ra.text.addingNewUserText'),
    },
    {
      header: translate('ra.text.removingUser'),
      text: translate('ra.text.removingUserText'),
    },
    {
      header: translate('ra.text.getInvoice'),
      text: translate('ra.text.getInvoiceText'),
    },
    {
      header: translate('ra.text.contactYou'),
      text: translate('ra.text.contactYouText'),
    },
  ]

  const hasActivatedAddons = () => {
    return subscriptionDetails?.addons.some((addon) => addon.enabled)
  }

  return (
    <>
      <div className={classes.infoContent}>
        <ConditionalRender renderValue={hasActivatedAddons()}>
          <Typography variant='h5' gutterBottom className={classes.typographyBold}>
            {translate('ra.text.subscriptionSummary')}
          </Typography>
          <BillingSummary nextBillingDate={subscriptionDetails.nextBillingDate} orgId={orgId} />
        </ConditionalRender>

        {(orgIsInSubscriptionState([SUBSCRIPTION_STATES.INACTIVE]) ||
          orgIsInSubscriptionState([SUBSCRIPTION_STATES.TRIAL])) &&
          hasActivatedAddons() && (
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant='contained'
                onClick={() => {
                  if (hasNullRequiredValues(subscriptionDetails)) {
                    setTab(1)
                    return
                  }
                  handleActivateSubscription()
                }}
                style={{
                  marginBottom: '0px',
                  marginTop: '10px',
                  width: '35%',
                  backgroundColor: COLORS.subscriptionBlue,
                  color: 'white',
                }}
              >
                {translate('ra.buttons.subscribe')}
              </Button>
            </div>
          )}

        <Typography
          variant='h5'
          className={classes.typographyBold}
          gutterBottom={
            !orgIsInSubscriptionState([SUBSCRIPTION_STATES.INACTIVE]) || !hasActivatedAddons()
          }
          style={{ marginTop: '25px' }}
        >
          {translate(SUBSCRIPTION_PAGE_TEXT[selectedOrgSubscriptionStatus])}
        </Typography>
        {orgIsInSubscriptionState([SUBSCRIPTION_STATES.INACTIVE]) && hasActivatedAddons() && (
          <Typography gutterBottom>{translate('ra.text.mainSubText')} </Typography>
        )}

        <FormControl className={classes.form}>
          {subscriptionDetails?.addons?.map((addon) => (
            <div key={addon.name} style={{ width: '100%' }}>
              <SubscriptionAddonCard
                addon={addon}
                isCurrentAddon={orgIsInSubscriptionState([
                  SUBSCRIPTION_STATES.ACTIVE,
                  SUBSCRIPTION_STATES.GRACE,
                ])}
                handleActivateAddon={handleActivateAddon}
                handleDeactivateAddon={(addon) => handleOpenDeactivateConfirmationModal(addon)}
              />
            </div>
          ))}
        </FormControl>
        <Typography variant='h5' gutterBottom className={classes.typographyBoldFaq}>
          {translate('ra.text.faq')}
        </Typography>
        <ExpandableText expandableText={EXPANDABLE_TEXT} allowMultipleExpanded />
      </div>
      <DeactivateSubscriptionConfirmationModal
        isOpen={isDeactivateAddonOpen}
        onClose={handleCloseDeactivateConfirmationModal}
        handleDeactivateAddon={() => {
          setIsDeactivateAddonOpen(false)
          handleDeactivateAddon(addon)
        }}
      />
    </>
  )
}

MainSubscriptionTab.propTypes = {
  handleActivateAddon: PropTypes.func,
  handleDeactivateAddon: PropTypes.func,
  subscriptionDetails: PropTypes.shape({
    id: PropTypes.string,
    status: PropTypes.string,
    totalSeats: PropTypes.number,
    allocatedSeats: PropTypes.number,
    availableSeats: PropTypes.number,
    nextBillingDate: PropTypes.string,
    vivaPaymentOrder: PropTypes.any,
    vivaPaymentOrderInitialTransactionId: PropTypes.any,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    street: PropTypes.string,
    city: PropTypes.string,
    streetNumber: PropTypes.string,
    zipCode: PropTypes.string,
    country: PropTypes.string,
    companyName: PropTypes.string,
    vatNumber: PropTypes.string,
    activity: PropTypes.string,
    needsInvoice: PropTypes.bool,
    addons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        pricing: PropTypes.string,
        seats: PropTypes.shape({
          all: PropTypes.number,
          allocated: PropTypes.number,
          free: PropTypes.number,
        }),
        enabled: PropTypes.bool,
        userActivatable: PropTypes.bool,
      }),
    ),
    cardFourLastDigits: PropTypes.string,
    coupon: PropTypes.any,
  }),
  setTab: PropTypes.func,
  handleActivateSubscription: PropTypes.func,
  orgId: PropTypes.string,
}

export default MainSubscriptionTab
