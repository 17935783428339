import { BASE_URL } from '../../utils/constants/url'
import axios from 'axios'
import generalHeader from '../../utils/constants/generalHeader'

const getLongLivedToken = async (accessToken) => {
    const url = `${BASE_URL}/social/long-lived-token?accessToken=${accessToken}`

    const response = await axios.get(url, {
        headers: generalHeader(localStorage.getItem('token')),
    })

    return response?.data
}

export default getLongLivedToken
