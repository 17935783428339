import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-admin'
import {
  DialogActions,
  Divider,
  makeStyles,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Tooltip,
} from '@material-ui/core'
import ConditionalRender from '../ConditionalRender/ConditionalRender'
import ButtonWithLoader from '../ui/ButtonWithLoader/ButtonWithLoader'
import getOrgQuote from '../../Apis/subscriptions/getOrgQuote'
import COLORS from '../../utils/constants/colors'
import InfoIcon from '@material-ui/icons/Info'
import ContainerPadding from '../containerPadding/containerPadding'
import mutationType from '../../types/mutationType'
import SUBSCRIPTION_STATES from '../../utils/constants/subscriptionStates'
import orgIsInSubscriptionState from '../../utils/helpers/orgIsInSubscriptionState'

const SubscriptionSeatsNotice = ({
  subscriptionDetails,
  invitations,
  userId,
  orgId,
  inviteUsersMutation,
}) => {
  const { addons } = subscriptionDetails || {}
  const translate = useTranslate()
  const [quotes, setQuotes] = useState({
    stats: null,
    livestream: null,
    coach: null,
  })

  // Extracting addons into constants
  const statsAddon = addons.find((addon) => addon.id === 'Stats')
  const livestreamAddon = addons.find((addon) => addon.id === 'Livestream')
  const coachAddon = addons.find((addon) => addon.id === 'Coach')

  // Separate invitations into different categories
  const statsInvitations = invitations.filter((inv) => inv.scopes.includes('Stats'))
  const livestreamInvitations = invitations.filter((inv) => inv.scopes.includes('Livestream'))
  const coachInvitations = invitations.filter((inv) => inv.scopes.includes('Coach'))

  useEffect(() => {
    // Function to fetch quotes
    const fetchQuotes = async () => {
      try {
        const statsQuote = statsAddon?.enabled
          ? await getOrgQuote({ orgId, addonType: statsAddon?.id, seats: statsInvitations.length })
          : null
        const livestreamQuote = livestreamAddon.enabled
          ? await getOrgQuote({
              orgId,
              addonType: livestreamAddon?.id,
              seats: 1,
            })
          : null
        const coachQuote = coachAddon?.enabled
          ? await getOrgQuote({ orgId, addonType: coachAddon?.id, seats: coachInvitations.length })
          : null

        setQuotes({
          stats: statsQuote,
          livestream: livestreamQuote,
          coach: coachQuote,
        })
      } catch {
        return
      }
    }

    fetchQuotes()
  }, [statsAddon, livestreamAddon, coachAddon])

  // Calculate the total price to pay
  const totalPriceToPay = [
    { addon: statsAddon, quote: quotes.stats },
    { addon: livestreamAddon, quote: quotes.livestream },
    { addon: coachAddon, quote: quotes.coach },
  ].reduce((total, { addon, quote }) => {
    return total + (quote?.amount || 0)
  }, 0)

  const totalPriceToPayBeforeCoupon = [
    { addon: statsAddon, quote: quotes.stats },
    { addon: livestreamAddon, quote: quotes.livestream },
    { addon: coachAddon, quote: quotes.coach },
  ].reduce((total, { addon, quote }) => {
    return total + (quote?.amountBeforeDiscount || 0)
  }, 0)

  const useStyles = makeStyles({
    table: {
      '& .MuiTableCell-head': {
        height: '24px',
        padding: '4px 8px',
      },
      '& .MuiTableRow-root': {
        height: '24px',
      },
    },
    cellStyle: {
      width: '20%',
      textAlign: 'center',
    },
  })

  const classes = useStyles()

  const handleInviteUsers = () => {
    const userDetails = {
      senderId: userId,
      orgId,
      invitations,
    }

    inviteUsersMutation.mutate(userDetails)
  }

  return (
    <div>
      <ContainerPadding padding='1%'>
        <TableContainer component={Paper} style={{ marginBottom: '5px' }}>
          <Table className={classes.table}>
            <TableHead>
              <TableRow style={{ backgroundColor: 'white', border: '2px solid lightgrey' }}>
                <TableCell className={classes.cellStyle}>
                  {translate('ra.text.addonType')}
                </TableCell>
                <TableCell className={classes.cellStyle}>
                  {translate('ra.text.invitations')}
                </TableCell>
                <TableCell className={classes.cellStyle}>{translate('ra.text.addonDes')}</TableCell>
                <TableCell className={classes.cellStyle}>
                  {translate('ra.text.totalCost')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {[
                {
                  addon: statsAddon,
                  invitations: statsInvitations,
                  quote: quotes.stats,
                },
                {
                  addon: coachAddon,
                  invitations: coachInvitations,
                  quote: quotes.coach,
                },
                {
                  addon: livestreamAddon,
                  invitations: livestreamInvitations,
                  quote: quotes.livestream,
                },
              ].map(
                ({ addon, invitations, quote }) =>
                  invitations.length > 0 &&
                  addon && (
                    <TableRow key={addon?.id}>
                      <TableCell className={classes.cellStyle}>{addon?.id}</TableCell>
                      <TableCell className={classes.cellStyle}>{invitations.length}</TableCell>
                      <TableCell className={classes.cellStyle}>
                        {quote?.amount ? quote?.description : '-'}
                      </TableCell>

                      <TableCell className={classes.cellStyle}>
                        {quote?.isDiscounted ? (
                          <>
                            <span style={{ textDecoration: 'line-through', marginRight: '8px' }}>
                              {quote?.amount && `€${quote?.amountBeforeDiscount}`}
                            </span>
                            <span>{quote?.amount ? `€${quote.amount}` : '-'}</span>
                          </>
                        ) : quote?.amount ? (
                          `€${quote?.amount}`
                        ) : (
                          '-'
                        )}
                      </TableCell>
                    </TableRow>
                  ),
              )}

              {[
                statsInvitations.length,
                livestreamInvitations.length,
                coachInvitations.length,
              ].some((length) => length > 0) && (
                <TableRow>
                  <TableCell colSpan={3} align='right'>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        gap: '5px',
                        marginRight:
                          subscriptionDetails?.coupon ||
                          orgIsInSubscriptionState([SUBSCRIPTION_STATES.TRIAL])
                            ? '0px'
                            : '25px',
                      }}
                    >
                      <Typography variant='body1' style={{ fontWeight: 'bold', marginRight: 5 }}>
                        {translate('ra.text.totalPrice')}
                      </Typography>
                      <ConditionalRender
                        renderValue={
                          subscriptionDetails?.coupon ||
                          orgIsInSubscriptionState([SUBSCRIPTION_STATES.TRIAL])
                        }
                      >
                        <Tooltip
                          title={
                            subscriptionDetails?.coupon
                              ? 'You have an activated coupon!'
                              : 'You are currently in a Trial period. During this time, there are no charges for users. However, once the Trial period ends, you will be billed for each seat occupied.'
                          }
                        >
                          <InfoIcon style={{ color: COLORS.grey }} />
                        </Tooltip>
                      </ConditionalRender>
                    </div>
                  </TableCell>
                  <TableCell className={classes.cellStyle}>
                    <Typography variant='body1' style={{ fontWeight: 'bold' }}>
                      {subscriptionDetails?.coupon ? (
                        <>
                          <span style={{ textDecoration: 'line-through', marginRight: '8px' }}>
                            €{totalPriceToPayBeforeCoupon.toFixed(2)}
                          </span>
                          <span>€{totalPriceToPay.toFixed(2)}</span>
                        </>
                      ) : (
                        <span>€{totalPriceToPay.toFixed(2)}</span>
                      )}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ContainerPadding>

      <div>
        <Divider />
        <DialogActions>
          <ButtonWithLoader
            label={translate('ra.buttons.invite')}
            onClick={handleInviteUsers}
            loading={inviteUsersMutation.isLoading}
          />
        </DialogActions>
      </div>
    </div>
  )
}

SubscriptionSeatsNotice.propTypes = {
  invitations: PropTypes.array.isRequired,
  userId: PropTypes.string,
  orgId: PropTypes.string,
  subscriptionDetails: PropTypes.object.isRequired,
  inviteUsersMutation: mutationType,
}

export default SubscriptionSeatsNotice
