import * as React from 'react'
import { useTranslate } from 'react-admin'
import '../../../../utils/constants/CSS/eventForms.css'
import { Field, Form, Formik } from 'formik'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import PropTypes from 'prop-types'
import FormContainer from '../../../layout/FormContainer/FormContainer'
import GetFormDirtyValue from '../../../getFormDirtyValue/getFormDirtyValue'
import MySelectField from '../../fields/MySelectField/MySelectField'
import parseEventFormSubmit from '../../../../utils/helpers/eventHelpers/parseEventFormSubmit'
import MyGroupIcon from '../../../../assets/icons/myGroupIcon'
import AutoChangeFakeEvent from '../../AutoChangeFakeEvent'
import MyLocationOnIcon from '../../../../assets/icons/myLocationOnIcon'
import PracticeEventFormFooter from '../../../PracticeEventFormFooter/PracticeEventFormFooter'
import { USER_SCOPES } from '../../../../utils/constants/userScopes'
import IsUserScopesAuthorized from '../../../IsUserScopesAuthorized/IsUserScopesAuthorized'
import useEventFormsValidations from '../../../../hooks/useEventFormsValidations'
import { isFieldDisabled } from '../../../../utils/helpers/isFieldDisabled'
import onEventFieldChange from '../../../../utils/helpers/eventHelpers/onEventFieldChange'
import PracticePlanRecentModal from '../../../PracticePlanRecentModal/PracticePlanRecentModal'
import QueryKeys from '../../../../utils/constants/queryKeys'
import addPracticePlan from '../../../../Apis/practice/addPracticePlan'
import useHistoryPush from '../../../../hooks/useHistoryPush'
import MyDateField from '../../fields/MyDateField'
import MyTimeField from '../../fields/MyTimeField'
import MyMultilineTextField from '../../fields/MyMultilineTextField'
import MyAutocomplete from '../../fields/MyAutocomplete'
import mutationType from '../../../../types/mutationType'
import teamType from '../../../../types/teamType'
import courtType from '../../../../types/courtType'
import eventVariantType from '../../../../types/events/eventVariantType'
import calendarDateClickType from '../../../../types/calendarDateClickType'
import eventRawType from '../../../../types/events/eventRawType'
import eventType from '../../../../types/events/eventType'
import EventFormLivestreamFields from '../../../EventFormLivestreamFields/EventFormLivestreamFields'
import GAME_EVENT_FORM_BROADCAST_FIELDS from '../../../../utils/constants/gameEventFormBroadcastFields'
import isCourtLivestreamEnabled from '../../../../utils/helpers/isCourtLivestreamEnabled'
import parseGameEventFormSubmit from '../../../../utils/helpers/eventHelpers/parseGameEventFormSubmit'
import {
  setEventEndTime,
  setEventStartTime,
} from '../../../../utils/helpers/eventHelpers/setEventTime'
import {
  BROADCAST,
  RECORD,
  LIVESTREAM_TYPE_OPTIONS_MAPPER_PRACTICE,
} from '../../../../utils/constants/livestreamTypeOptionsMapper'
import ConditionalRender from '../../../ConditionalRender/ConditionalRender'
import AddButton from '../../../ui/AddButton/AddButton'
import AddCourtModal from '../../../ui/AddCourtModal/AddCourtModal'
import localStorageHelper from '../../../../utils/helpers/localStorageHelper'
import localStorageIds from '../../../../utils/constants/localStorageIds'
import isUserOrgRolesValid from '../../../../utils/helpers/isUserOrgRolesValid'
import USER_ORG_ROLES from '../../../../utils/constants/userOrgRoles'
import USER_ORG_MEMBERS_ROLES from '../../../../utils/constants/userOrgMembersRoles'
import ids from '../../../../utils/constants/introIds'
import MyCourtIcon from '../../../../assets/icons/MyCourtIcon'

const PracticeEventForm = (props) => {
  const {
    clickedEventId,
    createEvent,
    updateEvent,
    event,
    selectedEventType,
    setFormDirty,
    currentTeam,
    teams,
    dateClick,
    eventsRaw,
    setEvents,
    courts,
  } = props
  const [practicePlansModal, setPracticePlansModal] = useState(false)
  const [selectedPracticePlan, setSelectedPracticePlan] = useState(null)
  const [practicePlanIdRedirect, setPracticePlanIdRedirect] = useState(null)
  const { place, court, notes, eventTeams, topic, broadcast, ytBroadcastDetails, practicePlayers } =
    event || {}
  const [selectedPracticePlayers, setSelectedPracticePlayers] = useState(practicePlayers)
  const { id, youtubeStreamKey, youtubeStreamKey2, state, streamerSaveUrl, type, saveType } =
    broadcast || {}

  const { broadcastId, thumbnailUrl, broadcastTitle, visibility } = ytBroadcastDetails || {}
  const [isCourtModalOpen, setIsCourtModalOpen] = useState(false)
  const orgLocalStorageHelper = localStorageHelper(localStorageIds.SELECTED_ORG)
  const hasSetYoutube = Boolean(orgLocalStorageHelper.localStorageValue?.youTubeChannelName)
  const useOrgYoutube = broadcastId || !youtubeStreamKey
  const { redirect } = useHistoryPush()
  const queryClient = useQueryClient()
  const translate = useTranslate()
  const { createLivestreamEventValidations, editLivestreamEventValidations } =
    useEventFormsValidations('practice', id, hasSetYoutube, useOrgYoutube)
  const { onFromDateFieldChange, onStartTimeFieldChange, onEndTimeFieldChange } = onEventFieldChange
  const { OWNER, ADMIN } = USER_ORG_ROLES
  const { TEAM_MANAGER } = USER_ORG_MEMBERS_ROLES
  const allowOrgYoutube =
    hasSetYoutube &&
    isUserOrgRolesValid(
      [OWNER, ADMIN, TEAM_MANAGER],
      teams?.map((team) => team.id),
    )

  const practicePlanCreateRedirect = (tms) => {
    redirect(`practice-plan/${practicePlanIdRedirect}/show`, [{ team: tms[0].id }], ['access'])
  }

  const addPracticePlanToEvent = useMutation((practicePlanId) => addPracticePlan(practicePlanId), {
    onSuccess: (res, { practiceTeams }) => {
      queryClient.invalidateQueries(QueryKeys.GET_PRACTICES)
      if (practicePlanIdRedirect) practicePlanCreateRedirect(practiceTeams)
    },
  })
  let teamContent
  if (currentTeam) {
    if (event) {
      teamContent = <span>{event.teams?.map((team) => team.name).join(', ')}</span>
    } else {
      teamContent = <span>{currentTeam.name}</span>
    }
  } else {
    teamContent = (
      <Field
        name='teams'
        label={translate('ra.formFields.teams')}
        multiple
        required
        component={MyAutocomplete}
        options={teams || []}
        getOptionLabel={(option) => option.name}
        defaultValue={event?.teams}
        fullWidth
        disabled={isFieldDisabled(state)}
      />
    )
  }

  return (
    <>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        validate={
          clickedEventId ? editLivestreamEventValidations : createLivestreamEventValidations
        }
        initialValues={{
          fromDate: setEventStartTime(event, dateClick),
          startTime: setEventStartTime(event, dateClick),
          endTime: setEventEndTime(event, dateClick),
          teams: event?.teams ?? [currentTeam],
          place: place ?? 'Court',
          courtId: court?.id ?? '',
          topic: topic ?? '',
          notes: notes ?? '',
          practicePlayers: selectedPracticePlayers ?? null,
          [GAME_EVENT_FORM_BROADCAST_FIELDS.IS_BROADCAST]:
            !!youtubeStreamKey || !!youtubeStreamKey2,
          [GAME_EVENT_FORM_BROADCAST_FIELDS.IS_RECORD]: !!streamerSaveUrl,
          [GAME_EVENT_FORM_BROADCAST_FIELDS.TYPE]: streamerSaveUrl
            ? LIVESTREAM_TYPE_OPTIONS_MAPPER_PRACTICE[RECORD](saveType)
            : LIVESTREAM_TYPE_OPTIONS_MAPPER_PRACTICE[BROADCAST](type),
          [GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY]: !useOrgYoutube
            ? youtubeStreamKey
            : null,
          [GAME_EVENT_FORM_BROADCAST_FIELDS.IS_ADDITIONAL_YOUTUBE_STREAM_KEY_ADDED]:
            !!youtubeStreamKey2,
          [GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_STREAM_KEY_2]: youtubeStreamKey2 ?? null,
          [GAME_EVENT_FORM_BROADCAST_FIELDS.EVENT_YT_CONFIG]:
            !useOrgYoutube || !allowOrgYoutube
              ? null
              : {
                  broadcastId: broadcastId ?? null,
                  [GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_THUMBNAIL]: thumbnailUrl ?? null,
                  [GAME_EVENT_FORM_BROADCAST_FIELDS.BROADCAST_TITLE]: broadcastTitle ?? '',
                  [GAME_EVENT_FORM_BROADCAST_FIELDS.YOUTUBE_VISIBILITY]: visibility ?? '',
                },
        }}
        onSubmit={(data) => {
          if (event) {
            updateEvent.mutate(
              {
                formData: parseEventFormSubmit(
                  parseGameEventFormSubmit(data, courts, selectedEventType),
                ),
                eventId: clickedEventId,
                eventType: selectedEventType,
              },
              {
                onSuccess: (response) => {
                  if (practicePlanIdRedirect) practicePlanCreateRedirect(response?.data.teams)
                },
              },
            )
          } else {
            createEvent.mutate(
              {
                formData: parseEventFormSubmit(
                  parseGameEventFormSubmit(data, courts, selectedEventType),
                ),
                eventType: selectedEventType,
              },
              {
                onSuccess: (response) => {
                  if (response?.data.place === 'Court' && selectedPracticePlan) {
                    addPracticePlanToEvent.mutate({
                      values: {
                        ...selectedPracticePlan?.values,
                        eventId: response?.data.id,
                      },
                    })
                  }
                },
              },
            )
          }
        }}
      >
        {({ values, setFieldValue }) => (
          <Form id={ids.createPractice.form}>
            <FormContainer>
              <MyDateField
                id={ids.createPractice.date}
                name='fromDate'
                label={translate('ra.formFields.date')}
                required
                disabled={isFieldDisabled(state)}
                onChange={onFromDateFieldChange}
              />
              <div
                id={ids.createPractice.time}
                className='div-flex-simple div-flex-responsive-1100'
              >
                <MyTimeField
                  name='startTime'
                  label={translate('ra.formFields.startTime')}
                  required
                  disabled={isFieldDisabled(state)}
                  onChange={onStartTimeFieldChange}
                />
                <MyTimeField
                  name='endTime'
                  label={translate('ra.formFields.endTime')}
                  required
                  disabled={isFieldDisabled(state, 'endTime')}
                  onChange={onEndTimeFieldChange}
                />
              </div>
              <div id={ids.createPractice.team} className='div-flex'>
                <MyGroupIcon />
                {teamContent}
              </div>

              <div className='div-flex'>
                <MyLocationOnIcon className='icon' />
                <MySelectField
                  name='place'
                  label={translate('ra.formFields.place')}
                  options={[
                    translate('ra.formFields.court'),
                    translate('ra.formFields.gym'),
                    translate('ra.formFields.other'),
                  ]}
                  disabled={isFieldDisabled(state) || event?.practicePlan}
                />
              </div>
              {values.place === 'Court' || place === 'Court' ? (
                <div id={ids.createPractice.courtName} className='div-flex'>
                  <MyCourtIcon />
                  <MySelectField
                    name='courtId'
                    label={translate('ra.formFields.courtName')}
                    options={courts}
                    emptyText={translate('ra.text.emptyCourts')}
                    fullWidth
                    disabled={isFieldDisabled(state)}
                    addOptionsComponent={
                      <AddButton
                        onClick={() => setIsCourtModalOpen(true)}
                        label={translate('ra.action.add_court')}
                        noHoverEffect={courts?.length}
                        style={{
                          paddingLeft: !courts?.length ? 8 : 4,
                          marginRight: !courts?.length ? 10 : 0,
                        }}
                      />
                    }
                  />

                  <AddCourtModal
                    isCourtModalOpen={isCourtModalOpen}
                    setIsCourtModalOpen={setIsCourtModalOpen}
                    setValue={(newCourtValue) => setFieldValue('courtId', newCourtValue)}
                  />
                </div>
              ) : null}
              <MyMultilineTextField
                name='notes'
                label={translate('ra.formFields.notes')}
                multiline
                minRows={5}
                fullWidth
                disabled={isFieldDisabled(state)}
              />

              <ConditionalRender renderValue={isCourtLivestreamEnabled(courts, values?.courtId)}>
                <IsUserScopesAuthorized acceptedScopes={[USER_SCOPES.LIVESTREAM]}>
                  <EventFormLivestreamFields
                    state={state}
                    allowOrgYoutube={allowOrgYoutube}
                    broadcastId={broadcastId ?? null}
                    emptyThumbnailNotAllowed={!!thumbnailUrl}
                    selectedEventType={selectedEventType}
                  />
                </IsUserScopesAuthorized>
              </ConditionalRender>
            </FormContainer>

            <GetFormDirtyValue setFormDirty={setFormDirty} />
            <AutoChangeFakeEvent
              clickedEventId={clickedEventId}
              selectedEventType={selectedEventType}
              eventsRaw={eventsRaw}
              setEvents={setEvents}
            />
            <IsUserScopesAuthorized acceptedScopes={[USER_SCOPES.COACH]}>
              <PracticeEventFormFooter
                practicePlan={
                  event?.practicePlan ||
                  (selectedPracticePlan && {
                    id: selectedPracticePlan?.values?.id,
                    description: selectedPracticePlan?.values?.description,
                    eventId: selectedPracticePlan?.values?.eventId,
                    practicePlanComponents: selectedPracticePlan?.values?.components,
                  })
                }
                practicePlayers={selectedPracticePlayers}
                practiceId={event?.id}
                formValues={values}
                render={values?.place === 'Court' || place === 'Court'}
                createEvent={createEvent}
                updateEvent={updateEvent}
                selectedEventType={selectedEventType}
                setPracticePlansModal={setPracticePlansModal}
                setSelectedPracticePlayers={setSelectedPracticePlayers}
                setSelectedPracticePlan={setSelectedPracticePlan}
                setPracticePlanIdRedirect={setPracticePlanIdRedirect}
                courts={courts}
              />
            </IsUserScopesAuthorized>
          </Form>
        )}
      </Formik>
      <IsUserScopesAuthorized acceptedScopes={[USER_SCOPES.COACH]}>
        <PracticePlanRecentModal
          open={practicePlansModal}
          onClose={() => {
            setPracticePlansModal(false)
          }}
          teamId={teams[0]?.id}
          eventId={event?.id}
          setSelectedPracticePlan={setSelectedPracticePlan}
        />
      </IsUserScopesAuthorized>
    </>
  )
}

PracticeEventForm.propTypes = {
  clickedEventId: PropTypes.string,
  createEvent: mutationType,
  updateEvent: mutationType,
  event: eventType,
  selectedEventType: eventVariantType,
  setFormDirty: PropTypes.func,
  currentTeam: teamType,
  teams: PropTypes.arrayOf(teamType),
  dateClick: calendarDateClickType,
  eventsRaw: PropTypes.arrayOf(eventRawType),
  setEvents: PropTypes.func,
  courts: PropTypes.arrayOf(courtType),
}

export default PracticeEventForm
