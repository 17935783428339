import React, { useState } from 'react'
import { Card, CardContent, CardHeader, makeStyles, Typography } from '@material-ui/core'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useRedirect, useTranslate } from 'react-admin'
import { USER_SCOPES } from '../../../utils/constants/userScopes'
import UseQueryParams from '../../../hooks/useQueryParams'
import youtubeUnlink from '../../../Apis/youtube/youtubeUnlink'
import AppIntegration from '../AppIntegration/AppIntegration'
import youtubeAuthorizeScreen from '../../../Apis/youtube/youtubeAuthorizeScreen'
import QueryKeys from '../../../utils/constants/queryKeys'
import googleAuthorizationCallback from '../../../Apis/youtube/googleAuthorizationCallback'
import localStorageIds from '../../../utils/constants/localStorageIds'
import useHistoryPush from '../../../hooks/useHistoryPush'
import CardWrapperMoreIcon from '../../../components/layout/CardWrapperMoreIcon/CardWrapperMoreIcon'
import appsPageStyles from './appsPage.styles'
import YoutubeConfigurationForm from '../YoutubeConfigurationForm/YoutubeConfigurationForm'
import youtubeLogoImage from '../../../assets/images/youtubeLogoImage.png'
import FacebookLogo from '../../../assets/images/FacebookLogo.png'
import FacebookLogoInvertedColor from '../../../assets/images/FacebookLogoInvertedColor.png'
import GoogleSignInIcon from '../../../assets/icons/GoogleSignInIcon'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import getAccounts from '../../../Apis/social/getAccounts'
import MetaAccountChoice from './MetaAccountChoice/MetaAccountChoice'
import MetaConfiguration from '../MetaConfiguration/MetaConfiguration'
import useGetLocalStorage from '../../../hooks/useGetLocalStorage'
import postUnlinkSocials from '../../../Apis/social/unlinkSocials'
import IsOrgAddonsAuthorized from '../../../components/IsOrgAddonsAuthorized/IsOrgAddonsAuthorized'
import getLongLivedToken from "../../../Apis/social/getLongLivedToken";

const AppsPage = () => {
  const [metaAccounts, setMetaAccounts] = useState([])
  const localStorageValue = useGetLocalStorage()
  const { [localStorageIds.SELECTED_ORG]: selectedOrg } = localStorageValue || {}
  const redirectToExternalUrl = useRedirect()
  const { redirect } = useHistoryPush()
  const queryParams = UseQueryParams()
  const queryClient = useQueryClient()
  const { code, org: orgId } = queryParams
  const translate = useTranslate()
  const useStyles = makeStyles(appsPageStyles)
  const classes = useStyles()

  const youTubeChannelName = selectedOrg?.youTubeChannelName || ''
  const isSocialConnected = selectedOrg?.isSocialConnected

  const invalidateQueries = () => {
    queryClient.invalidateQueries(QueryKeys.GET_ORGS_AFTER_LOGIN).then(() => {
      queryClient.invalidateQueries(QueryKeys.GET_TEAMS_AFTER_LOGIN).then(() => {})
    })
  }

  const isInvalidating = () => {
    return (
      queryClient.isFetching(QueryKeys.GET_ORGS) +
        queryClient.isFetching(QueryKeys.GET_ORGS_AFTER_LOGIN) +
        queryClient.isFetching(QueryKeys.GET_TEAMS_AFTER_LOGIN) >
      0
    )
  }

  const YoutubeAuthorizeScreen = useMutation(() => youtubeAuthorizeScreen(), {
    onSuccess: (res) => {
      redirectToExternalUrl(res.data)
    },
  })

  const youtubeUnlinkMutation = useMutation(() => youtubeUnlink({ orgId }), {
    onSuccess: () => {
      invalidateQueries()
    },
  })

  const metaLongLivedToken = useMutation((data)=>getLongLivedToken(data),{
    onSuccess: (res) => {
      metaAccountsMutation.mutate(res)
    }
  })

  const metaAccountsMutation = useMutation((data) => getAccounts(data), {
    onSuccess: (res) => {
      setMetaAccounts(res)
    },
  })

  const unlinkSocialsMutation = useMutation(() => postUnlinkSocials({ orgId }), {
    onSuccess: () => {
      invalidateQueries()
    },
  })

  const googleCallback = useQuery(
    [QueryKeys.GOOGLE_AUTHORIZATION_CALLBACK],
    () => googleAuthorizationCallback({ code, orgId }),
    {
      enabled: Boolean(code && orgId),
      retry: false,
      onSuccess: () => {
        redirect('/apps', [], ['code', 'scope'])
        invalidateQueries()
      },
      onError: () => {
        redirect('/apps', [], ['code', 'scope'])
      },
    },
  )

  const metaCallback = (res) => {
    const { accessToken } = res
    metaLongLivedToken.mutate(accessToken)
  }

  return (
    <CardWrapperMoreIcon>
      <Card className={classes.card}>
        <CardHeader
          title={
            <Typography className={classes.cardTitle} variant='h5'>
              {translate('ra.text.apps')}
            </Typography>
          }
          subheader={
            <Typography className={classes.cardSubtitle}>
              {translate('ra.text.manageIntegrations')}
            </Typography>
          }
        />
        <CardContent>
          <IsOrgAddonsAuthorized acceptedAddons={[USER_SCOPES.LIVESTREAM]}>
            <AppIntegration
              linkMutation={YoutubeAuthorizeScreen}
              unLinkMutation={youtubeUnlinkMutation}
              icon={<img className={classes.youtubeImage} src={youtubeLogoImage} alt='img' />}
              text={translate('ra.text.youtubeIntegrationText')}
              infoText={
                <div
                  dangerouslySetInnerHTML={{ __html: translate('ra.text.youtubeIntegrationInfo') }}
                />
              }
              linkButton={<GoogleSignInIcon />}
              isLoading={
                youtubeUnlinkMutation.isLoading ||
                googleCallback.isLoading ||
                YoutubeAuthorizeScreen.isLoading ||
                isInvalidating()
              }
              linkedAccountName={youTubeChannelName}
              configurationForm={<YoutubeConfigurationForm />}
            />
          </IsOrgAddonsAuthorized>
          {process.env.REACT_APP_RUNTIME !== 'PROD' && (
            <>
              <IsOrgAddonsAuthorized acceptedAddons={[USER_SCOPES.SOCIAL]}>
                <AppIntegration
                  linkMutation={() => {}}
                  unLinkMutation={unlinkSocialsMutation}
                  icon={<img className={classes.fbImage} src={FacebookLogo} alt='img' />}
                  text={translate('ra.text.metaIntegrationText')}
                  linkButton={
                    <FacebookLogin
                      appId='3898513280471149'
                      autoLoad={false}
                      scope='business_management,pages_show_list,instagram_basic,pages_manage_posts,instagram_content_publish,pages_read_engagement'
                      fields='name,email,picture'
                      size='small'
                      callback={metaCallback}
                      render={({ onClick }) => (
                        <button onClick={onClick} className={classes.metaButton}>
                          <img
                            style={{ width: 25, height: 25 }}
                            src={FacebookLogoInvertedColor}
                            alt='img'
                          />
                          {translate('ra.text.continueWithFB')}
                        </button>
                      )}
                    />
                  }
                  isLoading={metaLongLivedToken.isLoading || metaAccountsMutation.isLoading || unlinkSocialsMutation.isLoading}
                  linkedAccountName={isSocialConnected ? 'Connected Account' : ''}
                  configurationForm={<MetaConfiguration invalidateQueries={invalidateQueries} />}
                />
              </IsOrgAddonsAuthorized>
              <MetaAccountChoice
                accounts={metaAccounts}
                setMetaAccounts={setMetaAccounts}
                invalidateQueries={invalidateQueries}
              />
            </>
          )}
        </CardContent>
      </Card>
    </CardWrapperMoreIcon>
  )
}

export default AppsPage
